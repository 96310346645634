import { Link } from 'gatsby';
import React from 'react'
import styled from 'styled-components';

import FacebookIcon from "../../assets/icons/facebook-icon.svg";
import TwitterIcon from "../../assets/icons/twitter-icon.svg";
import YoutubeIcon from "../../assets/icons/youtube-icon.svg";

const StyledFooter = styled.div`
  color: #ffffff;
  @media only screen and (max-width: 1000px) {
    padding: 0 2.4rem;
  }
`;

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  height: 25.6rem;
  max-width: 120rem;
  margin: 0 auto;
  @media only screen and (max-width: 640px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const Copyright = styled.div`
  color: #E5E5E5;
  @media only screen and (max-width: 640px) {
    order: 2;
  }
`;

const Policies = styled.div`
  margin: 0 auto;
  a {
    position: relative;
    font-weight: bold;
    color: #ffffff;
    transition: opacity 0.15s ease-in-out;
    :not(:last-child) {
        margin-right: 4rem;
    }
    ::after {
      position: absolute;
      content: '';
      display: block;
      bottom: -0.2rem;
      width: 100%;
      height: 0.2rem;
      background: #fff;
      transform: translateY(0);
      opacity: 0;
      transition: all 0.15s ease-in-out;
    }
    :hover ::after {
      opacity: 1;
      transform: translateY(0.2rem);
    }
  }
`;

const Social = styled.div`
  a {
    &:not(:last-child) {
        margin-right: 2rem;
    }
  }
  @media only screen and (max-width: 640px) {
    margin: 2.4rem 0;
  }
`;

const Icon = styled.img`
`;

const currentYear = new Date().getFullYear();

const Footer = () => {
    return (
        <StyledFooter>
            <FooterContent>
                <Copyright>© {currentYear} ARVRtech</Copyright>
                <Policies>
                    <Link to="/privacy-policy/">Privacy policy</Link>
                    <Link to="/cookie-policy/">Cookie policy</Link>
                    <Link to="/terms-and-conditions/">Terms and Conditions</Link>
                </Policies>
                <Social>
                    <a href="https://twitter.com/ARVRtech">
                      <Icon src={TwitterIcon} />
                    </a>
                    <a href="https://www.facebook.com/ARVRtech/">
                      <Icon src={FacebookIcon} />
                    </a>
                    <a href="https://www.youtube.com/channel/UCZVzRD1ai0c9HdowcRDcOTg">
                      <Icon src={YoutubeIcon} />
                    </a>
                </Social>
            </FooterContent>
        </StyledFooter>
    )
}

export default Footer;
