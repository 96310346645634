import React from 'react';
import styled from 'styled-components';
import GlobalStyles from '../../styles/GlobalStyles';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

const StyledLayout = styled.div`
    padding: 0 6.4rem;
    background: #0B393F;
    @media only screen and (max-width: 1000px) {
      padding: 0 3.2rem;
    }
    @media only screen and (max-width: 800px) {
      padding: 0;
    }
`;

const Layout = ({ children }) => {
    return (
        <StyledLayout>
            <GlobalStyles />
            <Header />
            {children}
            <Footer />
        </StyledLayout>
    )
}

export default Layout;
