import { createGlobalStyle } from 'styled-components';
import "@fontsource/lato/400.css"
import "@fontsource/lato/700.css"

const GlobalStyles = createGlobalStyle`
    * {
      margin: 0;
      padding: 0;
      font-family: "Lato";
      box-sizing: border-box;
    }

    :root {
      font-size: 62.5%;
      --page-content-width: 120rem;
      --color-yellow: #FFDD00;
      --color-magenta: #D4003A;
      --color-green: #6EC8B2;
      --color-surface-light: #F3F7F6;
      --color-heading-light: #ffffff;
      --color-heading-dark: #104249;
      --color-body-light: #ffffff;
      --color-body-dark: #104249;
      --border-radius-surface: 2rem;
      --border-radius-element: 0.6rem;
      --margin-text-bottom: 2.4rem;
      --shadow-surface: 0px 38px 80px rgba(0, 0, 0, 0.07), 0px 11.4559px 24.1177px rgba(0, 0, 0, 0.0456112), 0px 4.75819px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.72094px 3.62304px rgba(0, 0, 0, 0.0243888);
      --shadow-element: 0px 0px 10px rgba(0, 0, 0, 0.25);;
    }

    body {
      font-size: 1.6rem;
    }

    h1 {
      max-width: 64rem;
      margin-bottom: var(--margin-text-bottom);
      font-size: 5.6rem;
      font-weight: 700;
      line-height: 0.9;
      color: var(--color-heading-light);
      @media only screen and (max-width: 1200px) {
        font-size: 4.8rem;
      }
      @media only screen and (max-width: 1000px) {
        font-size: 3.6rem;
      }
    }

    h2 {
      margin-bottom: var(--margin-text-bottom);
      font-weight: 700;
      font-size: 3.6rem;
      color: var(--color-heading-light);
      @media only screen and (max-width: 1200px) {
        font-size: 3.2rem;
      }
      @media only screen and (max-width: 1000px) {
        font-size: 2.8rem;
      }
    }

    h3 {
      margin-bottom: var(--margin-text-bottom);
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 1.5;
      color: var(--color-heading-dark);
      @media only screen and (max-width: 1200px) {
        font-size: 2rem;
      }
    }

    h4 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--color-heading-dark);
    }

    .subtitle {
      max-width: 55rem;
      margin-bottom: var(--margin-text-bottom);
      font-size: 2.6rem;
      color: #FFFFFF;
      @media only screen and (max-width: 1200px) {
        font-size: 2.2rem;
      }
      @media only screen and (max-width: 1000px) {
        font-size: 2rem;
      }
    }

    .caption {
      margin-bottom: var(--margin-text-bottom);
      font-weight: 700;
      font-size: 1.6rem;
      text-transform: uppercase;
      color: var(--color-magenta);
    }

    p {
      font-size: 1.8rem;
      line-height: 1.7;
    }

    a {
      text-decoration: none;
    }

`;

export default GlobalStyles;