import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from '../../assets/img/veerXR-logo.svg';

const StyledHeader = styled.header`
  position: relative;
  color: #ffffff;
  @media only screen and (max-width: 1000px) {
    padding: 0 2.4rem;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  height: 7.4rem;
  max-width: 120rem;
  margin: 0 auto;
`;

const StyledLogo = styled.img`
  @media only screen and (max-width: 1000px) {
    max-width: 12.5rem;
  }
`;


const Nav = styled.nav`
  margin-left: auto;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
  ul {
    display: flex;
  }
  li {
    list-style: none;
    :not(:last-child) {
        margin-right: 4rem;
    }
    a {
      position: relative;
      font-weight: bold;
      color: #ffffff;
      transition: opacity 0.15s ease-in-out;
      ::after {
        position: absolute;
        content: '';
        display: block;
        bottom: -0.2rem;
        width: 100%;
        height: 0.2rem;
        background: #fff;
        transform: translateY(0);
        opacity: 0;
        transition: all 0.15s ease-in-out;
      }
      :hover ::after {
        opacity: 1;
        transform: translateY(0.2rem);
      }
    }
  }
`;

const MobileNav = styled.nav`
  display: ${props => props.active ? 'block' : 'none'};
  margin-left: auto;
  position: absolute;
  right: 3.2rem;
  top: calc(100% - 0.8rem);
  @media only screen and (max-width: 480px) {
    left: 3.2rem;
  }
  ul {
    display: flex;
    flex-direction: column;
    padding: 4.8rem;
    font-size: 1.8rem;
    background: #ffffff;
    border-radius: var(--border-radius-surface);
    box-shadow: var(--shadow-surface);
  }
  li {
    list-style: none;
    :not(:last-child) {
          margin-right: 0;
          margin-bottom: 4.8rem;
    }
    a {
      font-weight: bold;
      color: var(--color-body-dark);
    }
  }
`;

const BurgerWrapper = styled.div`
  display: none;
  height: 22px;
  margin-left: auto;
  cursor: pointer;
  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;

const BurgerMenuButton = styled.span`
    display: inline-block;
    vertical-align: middle;
    width: 35px;
    height: 4px;
    background: ${props => props.active ? 'transparent' : "#ffffff"};
    position: relative;
    border-radius: 4px;
    transition: background 0.15s ease-in-out;
    &:before{
        position: absolute;
        content: "";
        display: block;
        height: 4px;
        right: 0;
        background: #ffffff;
        border-radius: 4px;
        transform-origin: right center;
        transition: all 0.15s ease-in-out;
        ${({ active }) => active
            ?   `width: 34px;
                        top: -12px;
                        visibility: visible;
                        transform: rotate(-45deg);`
            :   `width: 21px;
                        top: -9px;
                        transform: rotate(0deg);`
        }
    }
    &:after {
        position: absolute;
        content: "";
        display: block;
        height: 4px;
        right: 0;
        background: #ffffff;
        border-radius: 4px;
        transform-origin: right center;
        transition: all 0.15s ease-in-out;
        ${({ active }) => active
            ?   `width: 34px;
                        top: 12px;
                        visibility: visible;
                        transform: rotate(45deg);`
            :   `width: 28px;
                        top: 9px;
                        transform: rotate(0deg);`
        }
    }
`;

const menuItems = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Features',
    link: '/features/',
  },
  {
    name: 'Support Center',
    link: '/support-center/',
  },
  {
    name: 'About Us',
    link: '/about-us/',
  },
  {
    name: 'Contact Us',
    link: '/contact-us/',
  },
]

const Header = () => {
  const [menuOpen, toggleMenu] = useState(false);

  return (
    <StyledHeader>
      <HeaderContent>
        <StyledLogo src={Logo} />

        <BurgerWrapper onClick={() => toggleMenu(!menuOpen)} >
          <BurgerMenuButton active={menuOpen} />
        </BurgerWrapper>

        <Nav>
          <ul>
            {menuItems.map(menuItem => (
              <li key={menuItem.name}>
                <Link to={menuItem.link} onClick={() => toggleMenu(false)} activeStyle={{ color: "#FFDD00" }}>{menuItem.name}</Link>
              </li>
            ))}
          </ul>
        </Nav>

        <MobileNav active={menuOpen} >
          <ul>
            {menuItems.map(menuItem => (
              <li key={menuItem.name}>
                <Link to={menuItem.link} onClick={() => toggleMenu(false)} activeStyle={{ color: "#D4003A" }}>{menuItem.name}</Link>
              </li>
            ))}
          </ul>
        </MobileNav>
      </HeaderContent>
    </StyledHeader>
  )
}

export default Header;
